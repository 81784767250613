import { HeadFC } from "gatsby";
import * as React from "react";
import { PostContent } from "../../../components/gui/atoms/post-content";
import { DesktopLayoutBasicColumn } from "../../../components/layout/desktop-layouts/desktop-layout-basic-column";
import { ProgramWindow } from "../../../components/gui/organisms/program-window/program-window";
import { SiteTransitionLink } from "../../../components/layout/transitions/site-transition-link";
import { useSiteTransition } from "../../../components/layout/transitions/use-site-transition";
import { DesktopId } from "../../../app/desktops";

export default function SpotifyGeneratorTermsPage() {
  const transitionToSpotify = useSiteTransition({ to: ".." });
  return (
    <DesktopLayoutBasicColumn
      desktopId={DesktopId.APPS_SPOTIFY_TERMS}
      canWindowsRearrange={true}
    >
      <ProgramWindow
        windowId="spotify-terms"
        title="Terms of Use - Spotify Screenshot Generator"
        buttons={[
          {
            id: "go-back-spotify",
            alt: "",
            label: "Go back",
            onClick() {
              transitionToSpotify();
            },
          },
        ]}
      >
        <PostContent>
          <h1>Terms of Use</h1>
          <p>
            These terms govern all use of the tool{" "}
            <SiteTransitionLink className="go-back-spotify-link" to="..">
              "Spotify Screenshot Generator"
            </SiteTransitionLink>
            . By using Spotify Screenshot Generator, you agree to these terms,
            to the extent that applicable laws and regulations allow. If you do
            not agree to all of these terms and conditions, then you may not use
            the tool. We reserve the right to deny or restrict service to
            anyone, especially those who do not follow these terms and
            restrictions.
          </p>

          <h1>Affiliations</h1>
          <p>
            "Spotify" is a trademark of Spotify AB. Spotify Screenshot Generator
            is not affiliated in any way to Spotify AB.
          </p>

          <h1>Usage Policy</h1>
          <p>
            By using Spotify Screenshot Generator, you agree to use this service
            lawfully and ethically, and in accordance to these rules:
          </p>
          <ol>
            <li>
              You do not use generated images in ill intent; such as to harass,
              attack, incite violence, slander, threaten, disparage, intimidate,
              or otherwise hurt other people or entities;
            </li>
            <li>
              You do not use generated images as any kind of evidence (except
              for misuse of Spotify Screenshot Generator by its users);
            </li>
            <li>
              You do not use generated images to spread false information;
            </li>
            <li>You do not present generated images as fact;</li>
            <li>
              You make it obvious to any viewers that generated images are not
              genuine.
            </li>
          </ol>

          <h1>Liability</h1>
          <p>
            By using Spotify Screenshot Generator, you agree to be held legally
            responsible for anything that you generate. We are not liable for or
            endorse the actions of its users, the content that its users create,
            or any damages caused by users of Spotify Screenshot Generator.
            Under no circumstances will we be liable for any loss, injury,
            claim, liability or damage of any kind resulting from your use of
            the website. To the maximum extent permitted by applicable law, we
            disclaim all responsibility for any loss, injury, claim, liability,
            or damage of any kind, including direct, indirect, incidental,
            special, consequential, or punitive damages of any kind (including,
            without limitation, attorneys' fees and other legal expenses)
            resulting from, arising out of or in any way related to your use of
            Spotify Screenshot Generator.
          </p>

          <h1>Warranty</h1>
          <p>
            Spotify Screenshot Generator is provided "as is", without warranty
            of any kind, express or implied, including but not limited to the
            warranties of merchantability, fitness for a particular purpose and
            non-infringement. In no event shall we be liable for any claim,
            damages or other liability, whether in an action of contract, tort
            or otherwise, arising from, out or in connection with Spotify
            Screenshot Generator or the use of Spotify Screenshot Generator. We
            do not make any warranty that Spotify Screenshot Generator will be
            error-free, or that access will be uninterrupted. Accessing and
            using Spotify Screenshot Generator is at your own risk.
          </p>

          <p>
            <em>Last updated November 10, 2022</em>
          </p>
        </PostContent>
      </ProgramWindow>
    </DesktopLayoutBasicColumn>
  );
}

export const Head: HeadFC = () => (
  <title>Spotify Screenshot Generator - Terms of Use | simontang.dev</title>
);
