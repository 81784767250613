import React from "react";
import styled from "styled-components";
import {
  BaseDesktopLayout,
  BaseDesktopLayoutProps,
} from "./base-desktop-layout";

interface StyledDesktopLayoutBasicColumnProps {
  $alignItems?: string;
}

const StyledDesktopLayoutBasicColumn = styled(
  BaseDesktopLayout
)<StyledDesktopLayoutBasicColumnProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.$alignItems ?? "center"};
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

interface DesktopLayoutBasicColumnProps
  extends Omit<BaseDesktopLayoutProps, "isBoundsCheckRelativeToViewport"> {
  children: React.ReactNode;
  customization?: {
    alignItems?: "center" | "flex-start" | "stretch";
  };
}

export const DesktopLayoutBasicColumn = (
  props: DesktopLayoutBasicColumnProps
) => {
  const { customization, ...restProps } = props;
  return (
    <StyledDesktopLayoutBasicColumn
      $alignItems={customization?.alignItems}
      {...restProps}
      isBoundsCheckRelativeToViewport={false}
    />
  );
};
